import { useRouter } from 'next/router';
import { getCsrfToken, getProviders, signIn, useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { addAfterSignInAction } from '~/src/services/afterSignInAction';
import translatedUrl from '~/src/utils/translatedUrl';

import { WRITE_REVIEW_COOKIE_KEY } from '../../types/directus';
import { addWriteReviewCookies } from '../../utils/helper';
import { AhotuButton } from '../atoms/AhotuButton';
import AhotuLink from '../atoms/AhotuLink';
import AhotuForm from '../atoms/form/AhotuForm';
import AhotuPassword from './AhotuPassword';
import { SocialSignIn } from './SocialSignIn';

const WrongCredentials = () => {
  const { t } = useTranslation();
  return <span className="text-red-100 text-center mt-6">{t('session:wrong-credentials')}</span>;
};

export type Provider = {
  id: string;
  name: string;
  type: string;
  signinUrl: string;
  callbackUrl: string;
};

interface SignInOptionsProps {
  passwordSignInCallback?: () => void;
  onClose?: () => void;
}
const SignInOptions = ({ passwordSignInCallback, onClose }: SignInOptionsProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [providers, setProviders] = useState<any>();
  const [csrfToken, setCsrfToken] = useState('');

  const { data: session } = useSession();
  const router = useRouter();
  const { t } = useTranslation();

  const handleSubmit = async event => {
    setIsLoading(true);
    event.preventDefault();
    const writeReviewData = localStorage.getItem(WRITE_REVIEW_COOKIE_KEY);

    if (writeReviewData) {
      addWriteReviewCookies(JSON.parse(writeReviewData));
    } else {
      addAfterSignInAction();
    }

    const payload = {
      identifier: event.target.identifier.value,
      password: event.target.password.value,
      locale: event.target.locale.value,
      csrfToken: event.target.csrfToken.value,
      redirect: false
    };

    await signIn('credentials', payload)
      .then(res => {
        if (res.error) {
          setWrongCredentials(true);
        } else {
          if (passwordSignInCallback) {
            passwordSignInCallback();
            return;
          } else {
            router.push(res.url, res.url, { shallow: false });
          }
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (router.query.error === 'credentials') {
      setWrongCredentials(true);
    }
  }, [session]);

  useEffect(() => {
    const getInitialProps = async () => {
      const providers = await getProviders();
      const csrfToken = await getCsrfToken();

      setProviders(providers);
      setCsrfToken(csrfToken);
    };
    getInitialProps();
  }, []);

  return providers && csrfToken ? (
    <>
      {Object.values(providers).map(provider => {
        return provider['id'] === 'google' ? (
          <SocialSignIn key={provider['name']} provider={provider} userType="athlete" type="signin" />
        ) : (
          provider['id'] === 'credentials' && (
            <div key={provider['name']} className="flex flex-col mt-8">
              <div className="flex flex-row justify-center text-gray-50">{t('session:log-in-ahotu')}</div>
              {wrongCredentials && <WrongCredentials />}
              <form className="space-y-6" onSubmit={handleSubmit}>
                <input name="csrfToken" type="hidden" defaultValue={csrfToken} />
                <input name="locale" type="hidden" defaultValue={router.locale} />
                <AhotuForm.Group>
                  <AhotuForm.Label htmlFor="identifier">
                    {t('common:email')} / {t('common:username')} / {t('common:organisation')}
                  </AhotuForm.Label>
                  <AhotuForm.Input id="identifier" name="identifier" />
                </AhotuForm.Group>
                <AhotuForm.Group>
                  <AhotuForm.Label htmlFor="password">{t('session:password')}</AhotuForm.Label>
                  <AhotuPassword id="password" name="password" />
                </AhotuForm.Group>
                <AhotuButton
                  isLoading={isLoading}
                  text={t('session:sign-in')}
                  type="submit" //onClick={() => signIn(provider.id)}
                />
              </form>
              <div className="flex flex-row justify-end py-6">
                <AhotuLink
                  onClick={onClose}
                  className="underline underline-offset-2"
                  href={translatedUrl('forgotPassword', router.locale)}>
                  {t('session:forgot-password')}?
                </AhotuLink>
              </div>
            </div>
          )
        );
      })}
    </>
  ) : (
    <div></div>
  );
};

export default SignInOptions;
