import dayjs from 'dayjs';
import { signIn } from 'next-auth/react';
import { useTranslation } from 'next-i18next';

import { addAfterSignInAction } from '~/src/services/afterSignInAction';

import { WRITE_REVIEW_COOKIE_KEY } from '../../types/directus';
import { addWriteReviewCookies } from '../../utils/helper';
import { AhotuButton } from '../atoms/AhotuButton';
import { GoogleIcon } from '../icons/socialMedia';

const providerClasses = {
  google: {
    styles: 'text-softblack hover:bg-white',
    icon: GoogleIcon
  }
};

interface ISignInProps {
  provider: any;
  userType: 'athlete' | 'organizer';
  type: 'signin' | 'signup';
}

export const SocialSignIn = ({ provider, userType, type }: ISignInProps) => {
  const { t } = useTranslation();

  return (
    <AhotuButton
      className={providerClasses[provider.id].styles}
      onClick={() => {
        const expires = dayjs().add(1, 'm').toDate();
        document.cookie = `userType=${userType};path=/;expires=${expires}`;

        const writeReviewData = localStorage.getItem(WRITE_REVIEW_COOKIE_KEY);

        if (writeReviewData) {
          addWriteReviewCookies(JSON.parse(writeReviewData));
        } else {
          addAfterSignInAction();
        }

        signIn(provider.id);
      }}
      type="button"
      variant="stroke"
      iconLeft={providerClasses[provider.id].icon}
      text={`${type === 'signin' ? t('session:log-in-social') : t('session:sign-up-social')}  ${provider.name}`}
      iconClassName="h-7 w-7 mr-1"
    />
  );
};
