import Link, { LinkProps } from 'next/link';

import clsxm from '../../lib/clsxm';

interface IAhotuLink extends LinkProps {
  children: React.ReactNode;
  className?: string;
  type?: 'default' | 'underlined';
  id?: string;
}

const AhotuLink = ({ children, className, type = 'default', id, ...props }: IAhotuLink) => {
  const { onClick, ...rest } = props;

  return (
    <Link
      passHref
      {...rest}
      className={clsxm('ah-link', type === 'underlined' && 'link-underlined', className)}
      id={id}
      onClick={onClick}>
      {children}
    </Link>
  );
};

export default AhotuLink;
