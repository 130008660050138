import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { useSnackbarContext } from '~/src/contexts/SnackbarProvider/SnackbarProvider';
import { MODAL_MOBILE_BREAKPOINT } from '~/src/modal/constants';

import clsxm from '../../../lib/clsxm';
import {
  DEFAULT_EDITION_ID_OPTIONS,
  DEFAULT_RACE_ID_OPTIONS,
  REVIEW_MOBILE_BREAKPOINT,
  WRITE_A_REVIEW_LABEL
} from '../../../lib/reviews/constants';
import { createReview, CreateReviewsResponse } from '../../../services/reviews';
import { WRITE_REVIEW_COOKIE_KEY } from '../../../types/directus';
import { EventPageData } from '../../../types/eventPageTypes';
import { handleClientSideError } from '../../../utils/handleClientSideError';
import useMediaQuery from '../../../utils/useMediaQuery';
import { usePrevious } from '../../../utils/usePrevious';
import { useWriteReviewValidationSchema } from '../../../utils/useValidationSchema';
import AhotwoProperty from '../../atoms/AhotwoProperty';
import { AhotuForm } from '../../atoms/form';
import { Modal } from '../../atoms/Modal';
import PanelSliderMobile from '../../atoms/PanelSliderMobile';
import RatingStarSelect from '../../atoms/RatingStarSelect';
import { XIcon } from '../../icons/icons';
import TopNavigationSearchMobile from '../../molecules/TopNavigationSearchMobile';
import SignInGroup from '../SignInGroup';
import ReviewSubmitted from './ReviewSubmitted';
import WriteReviewForm from './WriteReviewForm';

interface WriteReviewGroupProps {
  event: EventPageData;
  isFirst?: boolean;
  className?: string;
}
const WriteReviewGroup = ({ event, isFirst = false, className = '' }: WriteReviewGroupProps) => {
  const [showSignInModal, setShowSignInModal] = useState(false);
  const [showWriteReviewModal, setShowWriteReviewModal] = useState(false);
  const [showReviewSubmittedModal, setShowReviewSubmittedModal] = useState(false);
  const [showSearchEventModal, setShowSearchEventModal] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  //on first render, session is null then gets updated.
  const { t } = useTranslation();
  const { data: session } = useSession();
  const router = useRouter();
  const { queue } = useSnackbarContext();

  const previousSession = usePrevious(session);

  useEffect(() => {
    //use previousSession to only update the modal when the session is updated the first time
    if (router.query.writeReview === 'true' && session && !previousSession) {
      setShowSignInModal(false);
      setShowWriteReviewModal(true);
    } else if (router.query.writeReview === 'true' && !session && !previousSession) {
      setShowSignInModal(true);
      setShowWriteReviewModal(false);
    }
  }, [router.query, session]);

  useEffect(() => {
    // TODO: Ask @jules2802 to explain why we need this and fix the login loop for old users.
    //Temporarily add this to disconnect user that don't the new keys in the session
    // if (session && (!session?.country) {
    //   signOut();
    // }
  }, [session]);

  const onWriteReviewClick = e => {
    if (!isNaN(e)) {
      setValue('rating', e);
    }
    if (session) {
      setShowWriteReviewModal(true);
    } else {
      localStorage.setItem(
        WRITE_REVIEW_COOKIE_KEY,
        JSON.stringify({
          eventPageUrl: '/' + router.locale + router.asPath
        })
      );
      setShowSignInModal(true);
    }
  };

  const passwordSignInCallback = () => {
    setShowSignInModal(false);
    setShowWriteReviewModal(true);
    localStorage.removeItem(WRITE_REVIEW_COOKIE_KEY);
  };

  const onCloseWriteReviewModal = () => {
    setShowWriteReviewModal(false);
  };

  const onCloseSignInModal = () => {
    setShowSignInModal(false);
  };

  const onCloseReviewSubmitted = () => {
    setShowReviewSubmittedModal(false);
  };

  const writeReviewFormProps = useForm({
    resolver: yupResolver(useWriteReviewValidationSchema()),
    reValidateMode: 'onBlur',
    defaultValues: {
      rating: null,
      eventId: null,
      edition: DEFAULT_EDITION_ID_OPTIONS,
      race: DEFAULT_RACE_ID_OPTIONS,
      text: '',
      isAuthentic: false
    }
  });
  const { setValue, handleSubmit, reset } = writeReviewFormProps;

  const onSubmit = values => {
    setIsLoading(true);
    createReview({ ...values, memberId: session.id })
      .then(({ status, review }: CreateReviewsResponse) => {
        if (status === 200) {
          setShowWriteReviewModal(false);
          reset();
          setShowReviewSubmittedModal(true);
        } else {
          handleClientSideError('Error on create Review:', review);
          queue(t('common:generic-error'), true);
        }
      })
      .catch(error => {
        handleClientSideError('Error on create Review: ', error);
        if (error.status === 500) queue(t('common:generic-error'), true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className={clsxm('flex flex-col items-center space-y-4', className)}>
      {isFirst && (
        <>
          <RatingStarSelect starClassName="w-10 h-10" onClick={onWriteReviewClick} />
          <span
            className="block text-center"
            dangerouslySetInnerHTML={{ __html: t('event-page:write-first-review') }}></span>
        </>
      )}
      <div className="hover:cursor-pointer" id={WRITE_A_REVIEW_LABEL} onClick={onWriteReviewClick}>
        <AhotwoProperty className="font-semibold underline">{t('event-page:write-review')}</AhotwoProperty>
      </div>

      {showSignInModal && (
        <ShowSignInModalGroup
          headingText={t('event-page:log-in-write-review')}
          onCloseSignInModal={onCloseSignInModal}
          passwordSignInCallback={passwordSignInCallback}
        />
      )}

      {showWriteReviewModal && (
        <FormProvider {...writeReviewFormProps}>
          <AhotuForm onSubmit={handleSubmit(onSubmit)}>
            <ShowWriteReviewModalGroup
              onCloseWriteReviewModal={onCloseWriteReviewModal}
              isLoading={isLoading}
              event={event}
            />
          </AhotuForm>
        </FormProvider>
      )}

      {showReviewSubmittedModal && (
        <ReviewSubmittedModalGroup
          onCloseReviewSubmitted={onCloseReviewSubmitted}
          setShowSearchEvent={setShowSearchEventModal}
        />
      )}
      {showSearchEventModal && <ShowMobileSearchEventModal setShowSearchBox={setShowSearchEventModal} />}
    </div>
  );
};
export default WriteReviewGroup;

export const ShowSignInModalGroup = ({ headingText, onCloseSignInModal, passwordSignInCallback }) => {
  const isMobile = useMediaQuery(MODAL_MOBILE_BREAKPOINT);

  return isMobile ? (
    <div className="-mt-12">
      <PanelSliderMobile side="right">
        <div
          className="flex justify-end items-center pr-4  !h-[73px] -top-4 bg-white z-20"
          onClick={onCloseSignInModal}>
          <XIcon className="h-4 w-4" />
        </div>
        <SignInGroup
          headingLevel="h3"
          headingSize="3xl"
          headingClassName="text-center mb-8"
          headingText={headingText}
          passwordSignInCallback={passwordSignInCallback}
          onClose={onCloseSignInModal}
        />
      </PanelSliderMobile>
    </div>
  ) : (
    <Modal onClose={onCloseSignInModal}>
      <SignInGroup
        onClose={onCloseSignInModal}
        headingLevel="h3"
        headingSize="3xl"
        headingClassName="text-center mb-8"
        headingText={headingText}
        passwordSignInCallback={passwordSignInCallback}
      />
    </Modal>
  );
};

const ShowWriteReviewModalGroup = ({ event, isLoading, onCloseWriteReviewModal }) => {
  //TODO: refactor to parent component
  const isMobile = useMediaQuery(REVIEW_MOBILE_BREAKPOINT);

  return isMobile ? (
    <div className="-mt-12">
      <PanelSliderMobile side="right" id="writeReviewModal">
        <div
          className="flex justify-end items-center pr-4  !h-[73px] -top-4 bg-white z-20"
          onClick={onCloseWriteReviewModal}>
          <XIcon className="h-4 w-4" />
        </div>
        <WriteReviewForm event={event} isSaving={isLoading} />
      </PanelSliderMobile>
    </div>
  ) : (
    <Modal widthClass="sm:max-w-4xl mx-10" onClose={onCloseWriteReviewModal}>
      <WriteReviewForm event={event} isSaving={isLoading} />
    </Modal>
  );
};

const ReviewSubmittedModalGroup = ({ onCloseReviewSubmitted, setShowSearchEvent }) => {
  const isMobile = useMediaQuery(REVIEW_MOBILE_BREAKPOINT);

  return isMobile ? (
    <div className="-mt-12">
      <PanelSliderMobile side="right">
        <div
          className="flex justify-end items-center pr-4  !h-[73px] -top-4 bg-white z-20"
          onClick={onCloseReviewSubmitted}>
          <XIcon className="h-4 w-4" />
        </div>
        <ReviewSubmitted setShowSearchEvent={setShowSearchEvent} />
      </PanelSliderMobile>
    </div>
  ) : (
    <Modal widthClass="sm:max-w-4xl mx-10" onClose={onCloseReviewSubmitted}>
      <ReviewSubmitted setShowSearchEvent={setShowSearchEvent} />
    </Modal>
  );
};

const ShowMobileSearchEventModal = ({ setShowSearchBox }) => {
  return <TopNavigationSearchMobile setSearchIsExpanded={setShowSearchBox} searchIsExpanded={true} />;
};
