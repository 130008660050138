import { SportReference } from '../types/referenceTypes';
const dict: SportReference = {
  '0': {
    id: 0,
    code: 'run',
    display: 'single_activity',
    names: {
      en: 'Running',
      fr: 'Course',
      de: 'Laufen',
      es: 'Running',
      pt: 'Corrida',
      pl: 'Bieganie',
      'pt-BR': 'Corrida',
      sv: 'Löpning',
      zh: '跑步',
      ko: '러닝',
      ja: 'ランニング',
      ru: 'Бег',
      th: 'วิ่ง',
      it: 'Running',
      nl: 'Hardlopen',
      tr: 'Koşu',
      id: 'Berlari'
    },
    permalinks: {
      en: 'running',
      fr: 'course',
      de: 'laufen',
      es: 'running',
      pt: 'corrida',
      pl: 'bieganie',
      'pt-BR': 'corrida',
      sv: 'lopning',
      ru: '',
      it: 'running',
      nl: 'hardlopen',
      tr: 'kosu'
    },
    order: 1,
    group: 'group_run',
    type: 'type_run',
    isFilter: true,
    titles: {
      en: 'Running races',
      fr: 'Courses à pied',
      de: 'Laufrennen',
      es: 'Carreras de running',
      pt: 'Corridas',
      pl: 'Wyścigi biegowe',
      'pt-BR': 'Corridas de rua',
      sv: 'Löptävlingar',
      zh: '跑步比赛',
      ko: '러닝 레이스',
      ja: 'ランニングレース',
      ru: 'Беговые гонки',
      th: 'Running races',
      it: 'Gare di running',
      nl: 'Hardloopwedstrijden',
      tr: 'Koşu yarışları',
      id: 'Perlombaan lari'
    }
  },
  '1': {
    id: 1,
    code: 'walk',
    display: 'single_activity',
    names: {
      en: 'Walking',
      fr: 'Marche',
      de: 'Walking',
      es: 'Marcha',
      pt: 'Caminhada',
      pl: 'Marsz',
      'pt-BR': 'Caminhada',
      sv: 'Gång',
      zh: '步行',
      ko: '걷기',
      ja: 'ウォーキング',
      ru: 'Ходьба',
      th: 'Walking',
      it: 'Camminata',
      nl: 'Wandelen',
      tr: 'Yürüyüş',
      id: 'Berjalan'
    },
    permalinks: {
      en: 'walking',
      fr: 'marche',
      de: 'walking',
      es: 'marcha',
      pt: 'caminhada',
      pl: 'marsz',
      'pt-BR': 'caminhada',
      sv: 'gang',
      ru: '',
      it: 'camminata',
      nl: 'wandelen',
      tr: 'yuruyus'
    },
    order: 22,
    group: 'group_run',
    type: 'type_other',
    isFilter: true,
    titles: {
      en: 'Walking races',
      fr: 'Marches',
      de: 'Walking Rennen',
      es: 'Carreras marcha',
      pt: 'Caminhadas',
      pl: 'Wyścigi piesze',
      'pt-BR': 'Caminhadas',
      sv: 'Gångtävlingar',
      zh: '竞走比赛',
      ko: '걷기 레이스',
      ja: 'ウォーキングレース',
      ru: 'Ходьба наперегонки',
      th: 'Walking races',
      it: 'Gare di camminata',
      nl: 'Loopwedstrijden',
      tr: 'Yürüyüş yarışları',
      id: 'Perlombaan jalan kaki'
    }
  },
  '2': {
    id: 2,
    code: 'nordic_walk',
    display: 'single_activity',
    names: {
      en: 'Nordic walking',
      fr: 'Marche nordique',
      de: 'Nordic walking',
      es: 'Marcha nórdica',
      pt: 'Caminhada nórdica',
      pl: 'Nordic walking',
      'pt-BR': 'Caminhada nórdica',
      sv: 'Stavgång',
      zh: '北欧式健走',
      ko: '노르딕 워킹',
      ja: 'ノルディックウォーキング',
      ru: 'Скандинавская ходьба',
      th: 'Nordic walking',
      it: 'Camminata nordica',
      nl: 'Nordic walking',
      tr: 'Nordik yürüyüşü',
      id: 'Berjalan ala nordik'
    },
    permalinks: {
      en: 'nordic-walking',
      fr: 'marche-nordique',
      de: 'nordic-walking',
      es: 'marcha-nordica',
      pt: 'caminhada-nordica',
      pl: 'nordic-walking',
      'pt-BR': 'caminhada-nordica',
      sv: 'stavgang',
      ru: '',
      it: 'camminata-nordica',
      nl: 'nordic-walking',
      tr: 'nordik-yuruyusu'
    },
    order: 23,
    group: null,
    type: 'type_other',
    isFilter: true,
    titles: {
      en: 'Nordic walking races',
      fr: 'Marches nordiques',
      de: 'Nordic Walking Rennen',
      es: 'Carreras de marcha nórdica',
      pt: 'Corridas de caminhada nórdica',
      pl: 'Wyścigi nordic walking',
      'pt-BR': 'Corridas de caminhada nórdica',
      sv: 'Tävlingar i stavgång',
      zh: '北欧健走比赛',
      ko: '노르딕 워킹 레이스',
      ja: 'ノルディックウォーキングレース',
      ru: 'Забеги по скандинавской ходьбе',
      th: '\nNordic walking races',
      it: 'Gare di nordic walking',
      nl: 'Nordic Walking wedstrijden',
      tr: 'Nordik yürüyüş yarışları',
      id: 'Perlombaan jalan kaki ala Nordik'
    }
  },
  '3': {
    id: 3,
    code: 'mtb',
    display: 'single_activity',
    names: {
      en: 'Mountain biking',
      fr: 'Vtt',
      de: 'Mountainbiken',
      es: 'Ciclismo de montaña',
      pt: 'Mountain bike',
      pl: 'Kolarstwo górskie',
      'pt-BR': 'Bicicleta de montanha',
      sv: 'Mountainbike',
      zh: '山地自行车',
      ko: '산악 자전거',
      ja: 'マウンテンバイク',
      ru: 'Горный велосипед',
      th: 'การปั่นจักรยานเสือภูเขา',
      it: 'Mountain bike',
      nl: 'Mountainbiken',
      tr: 'Dağ bisikleti sürüşü',
      id: 'Bersepeda gunung'
    },
    permalinks: {
      en: 'mountain-biking',
      fr: 'vtt',
      de: 'mountainbiken',
      es: 'ciclismo-de-montana',
      pt: 'mountain-bike',
      pl: 'kolarstwo-gorskie',
      'pt-BR': 'bicicleta-de-montanha',
      sv: 'mountainbike',
      ru: '',
      it: 'mountain-bike',
      nl: 'mountainbiken',
      tr: 'dag-bisikleti-surusu'
    },
    order: 6,
    group: 'group_cycling',
    type: 'type_cycling',
    isFilter: true,
    titles: {
      en: 'Mountain biking races',
      fr: 'Courses de VTT',
      de: 'Mountainbikerennen',
      es: 'Carreras de bicicleta de montaña',
      pt: 'Corridas de BTT',
      pl: 'Wyścigi kolarstwa górskiego',
      'pt-BR': 'Corridas de mountain bike',
      sv: 'Mountainbike-tävlingar',
      zh: '山地自行车赛',
      ko: '산악 자전거 레이스',
      ja: 'マウンテンバイクレース',
      ru: 'Гонки на горных велосипедах',
      th: 'Mountain biking races',
      it: 'Gare di mountain bike',
      nl: 'Mountainbikewedstrijden',
      tr: 'Dağ bisikleti yarışları',
      id: 'Perlombaan sepeda gunung'
    }
  },
  '4': {
    id: 4,
    code: 'canoeing',
    display: 'single_activity',
    names: {
      en: 'Canoeing',
      fr: 'Canoé',
      de: 'Kanufahren',
      es: 'Piragüismo',
      pt: 'Canoagem',
      pl: 'Kanadyjki',
      'pt-BR': 'Canoagem',
      sv: 'Kanot',
      zh: '独木舟',
      ko: '카누',
      ja: 'カヌー',
      ru: 'Каноэ',
      th: 'Canoeing',
      it: 'Canoa',
      nl: 'Kano',
      tr: 'Kano',
      id: 'Kano'
    },
    permalinks: {
      en: 'canoeing',
      fr: 'canoe',
      de: 'kanufahren',
      es: 'piraguismo',
      pt: 'canoagem',
      pl: 'kanadyjki',
      'pt-BR': 'canoagem',
      sv: 'kanot',
      ru: '',
      it: 'canoa',
      nl: 'kano',
      tr: 'kano'
    },
    order: 18,
    group: null,
    type: 'type_water',
    isFilter: false,
    titles: {
      en: 'Canoeing races',
      fr: 'Courses de canoé',
      de: 'Kanu Rennen',
      es: 'Regatas de piragüismo',
      pt: 'Corridas de canoagem',
      pl: 'Wyścigi kanadyjkowe',
      'pt-BR': 'Corridas de canoagem',
      sv: 'Tävlingar i kanotpaddling',
      zh: '皮划艇赛',
      ko: '카누 레이스',
      ja: 'カヌーレース',
      ru: 'Гонки на каноэ',
      th: '\nCanoeing races',
      it: 'Gare di canoa',
      nl: 'Kano wedstrijden',
      tr: 'Kano yarışları',
      id: 'Perlombaan kano'
    }
  },
  '5': {
    id: 5,
    code: 'kayaking',
    display: 'single_activity',
    names: {
      en: 'Kayaking',
      fr: 'Kayak',
      de: 'Kajakfahren',
      es: 'Kayac',
      pt: 'Caiaque',
      pl: 'Kajakarstwo',
      'pt-BR': 'Caiaque',
      sv: 'Kajak',
      zh: '皮划艇',
      ko: '카약',
      ja: 'カヤック',
      ru: 'Каякинг',
      th: 'Kayaking',
      it: 'Kayaking',
      nl: 'Kajakken',
      tr: 'Kano',
      id: 'Kayak'
    },
    permalinks: {
      en: 'kayaking',
      fr: 'kayak',
      de: 'kajakfahren',
      es: 'kayac',
      pt: 'caiaque',
      pl: 'kajakarstwo',
      'pt-BR': 'caiaque',
      sv: 'kajak',
      ru: '',
      it: 'kayaking',
      nl: 'kajakken',
      tr: 'kano'
    },
    order: 16,
    group: null,
    type: 'type_water',
    isFilter: false,
    titles: {
      en: 'Kayaking races',
      fr: 'Epreuves de kayak',
      de: 'Kajak Rennen',
      es: 'Regatas en kayak',
      pt: 'Corridas de caiaque',
      pl: 'Wyścigi kajakowe',
      'pt-BR': 'Corridas de caiaque',
      sv: 'Tävlingar i kajakpaddling',
      zh: '皮划艇赛',
      ko: '카약 레이스',
      ja: 'カヤックレース',
      ru: 'Гонки на каяках',
      th: 'Kayaking races',
      it: 'Gare di kayak',
      nl: 'Kajak wedstrijden',
      tr: 'Kano yarışları',
      id: 'Perlombaan kayak'
    }
  },
  '6': {
    id: 6,
    code: 'obstacle_race',
    display: 'single_activity',
    names: {
      en: 'Obstacle race',
      fr: 'Course à obstacles',
      de: 'Obstacle race',
      es: 'Carrera de obstáculos',
      pt: 'Corrida de obstáculos',
      pl: 'Bieg z przeszkodami',
      'pt-BR': 'Corrida de obstáculos',
      sv: 'Hinderbanelopp',
      zh: '障碍赛',
      ko: '장애물 경주',
      ja: '障害物レース',
      ru: 'Гонки с препятствиями',
      th: 'Obstacle race',
      it: 'Gara a ostacoli',
      nl: 'Hindernisrace',
      tr: 'Engelli yarış',
      id: 'Perlombaan halang rintang'
    },
    permalinks: {
      en: 'obstacle-race',
      fr: 'course-a-obstacles',
      de: 'obstacle-race',
      es: 'carrera-de-obstaculos',
      pt: 'corrida-de-obstaculos',
      pl: 'bieg-z-przeszkodami',
      'pt-BR': 'corrida-de-obstaculos',
      sv: 'hinderbanelopp',
      ru: '',
      it: 'gara-a-ostacoli',
      nl: 'hindernisrace',
      tr: 'engelli-yaris'
    },
    order: 24,
    group: 'group_run',
    type: 'type_other',
    isFilter: true,
    titles: {
      en: 'Obstacle races',
      fr: 'Courses à obstacles',
      de: 'Obstacle Races',
      es: 'Carreras de obstáculos',
      pt: 'Corridas de obstáculos',
      pl: 'Wyścigi z przeszkodami',
      'pt-BR': 'Corridas com obstáculos',
      sv: 'Hinderbanelopp (OCR)',
      zh: '障碍赛',
      ko: '장애물 경주',
      ja: '障害物レース',
      ru: 'Гонки с препятствиями',
      th: '\nObstacle races',
      it: 'Gare ad ostacoli',
      nl: 'Hindernis races',
      tr: 'Engelli yarışlar',
      id: 'Balapan rintangan'
    }
  },
  '7': {
    id: 7,
    code: 'run_and_bike',
    display: 'single_activity',
    names: {
      en: 'Bike & run',
      fr: 'Run and bike',
      de: 'Bike & run',
      es: 'Bicicleta y carrera',
      pt: 'Bicicleta e corrida',
      pl: 'Bike & run',
      'pt-BR': 'Bike & run',
      sv: 'Cykel och löpning',
      zh: '自行车与跑步',
      ko: '자전거&달리기',
      ja: 'バイク＆ラン',
      ru: 'Велосипед и бег',
      th: 'Bike & run',
      it: 'Bike &run',
      nl: 'Fietsen & rennen',
      tr: 'Bisiklet & koşu',
      id: 'Sepeda & lari'
    },
    permalinks: {
      en: 'bike-run',
      fr: 'run-and-bike',
      de: 'bike-run',
      es: 'bicicleta-y-carrera',
      pt: 'bicicleta-e-corrida',
      pl: 'bike-run',
      'pt-BR': 'bike-run',
      sv: 'cykel-och-lopning',
      ru: '',
      it: 'bike-run',
      nl: 'fietsen-rennen',
      tr: 'bisiklet-kosu'
    },
    order: 13,
    group: null,
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Run & bike races',
      fr: 'Run & bikes',
      de: 'Bike & Run Rennen',
      es: 'Carreras Run & bike',
      pt: 'Corrida e bicicleta',
      pl: 'Wyścigi Run & Bike',
      'pt-BR': 'Corridas Run & bike',
      sv: 'Run & Bike tävlingar',
      zh: '跑步和自行车赛',
      ko: '달리기&자전거 레이스',
      ja: 'ラン＆バイクレース',
      ru: 'Бег и велосипедные гонки',
      th: '\nRun & bike races',
      it: 'Gare di run & bike',
      nl: 'Hardloop- en fietsraces',
      tr: 'Koşu ve bisiklet yarışları',
      id: 'Balapan lari & sepeda'
    }
  },
  '8': {
    id: 8,
    code: 'duathlon',
    display: 'multi_activity',
    names: {
      en: 'Duathlon',
      fr: 'Duathlon',
      de: 'Duathlon',
      es: 'Duatlón',
      pt: 'Duatlo',
      pl: 'Duathlon',
      'pt-BR': 'Duatlo',
      sv: 'Duathlon',
      zh: '两项全能',
      ko: '듀애슬론',
      ja: 'デュアスロン',
      ru: 'Дуатлон',
      th: 'Duathlon',
      it: 'Duathlon',
      nl: 'Duathlon',
      tr: 'Duatlon',
      id: 'Duathlon'
    },
    permalinks: {
      en: 'duathlon',
      fr: 'duathlon',
      de: 'duathlon',
      es: 'duatlon',
      pt: 'duatlo',
      pl: 'duathlon',
      'pt-BR': 'duatlo',
      sv: 'duathlon',
      ru: '',
      it: 'duathlon',
      nl: 'duathlon',
      tr: 'duatlon'
    },
    order: 9,
    group: null,
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Duathlons',
      fr: 'Duathlons',
      de: 'Duathlons',
      es: 'Duatlones',
      pt: 'Duatlos',
      pl: 'Duathlony',
      'pt-BR': 'Duathlons',
      sv: 'Duathlons',
      zh: '两项全能',
      ko: '듀애슬론',
      ja: 'デュアスロン',
      ru: 'Дуатлоны',
      th: 'Duathlons',
      it: 'Duathlon',
      nl: 'Duathlons',
      tr: 'Duatlonlar',
      id: 'Duathlons'
    }
  },
  '9': {
    id: 9,
    code: 'triathlon',
    display: 'multi_activity',
    names: {
      en: 'Triathlon',
      fr: 'Triathlon',
      de: 'Triathlon',
      es: 'Triatlón',
      pt: 'Triatlo',
      pl: 'Triathlon',
      'pt-BR': 'Triatlo',
      sv: 'Triathlon',
      zh: '三项全能',
      ko: '트라이애슬론',
      ja: 'トライアスロン',
      ru: 'Триатлон',
      th: 'Triathlon',
      it: 'Triathlon',
      nl: 'Triatlon',
      tr: 'Triatlon',
      id: 'Triathlon (trilomba)'
    },
    permalinks: {
      en: 'triathlon',
      fr: 'triathlon',
      de: 'triathlon',
      es: 'triatlon',
      pt: 'triatlo',
      pl: 'triathlon',
      'pt-BR': 'triatlo',
      sv: 'triathlon',
      ru: '',
      it: 'triathlon',
      nl: 'triatlon',
      tr: 'triatlon'
    },
    order: 8,
    group: 'group_triathlon',
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Triathlons',
      fr: 'Triathlons',
      de: 'Triathlons',
      es: 'Triatlones',
      pt: 'Triatlos',
      pl: 'Triathlony',
      'pt-BR': 'Triatlos',
      sv: 'Triathlons',
      zh: '铁人三项赛',
      ko: '트라이애슬론',
      ja: 'トライアスロン',
      ru: 'Триатлоны',
      th: 'Triathlons',
      it: 'Triathlon',
      nl: 'Triatlons',
      tr: 'Triatlonlar',
      id: 'Triathlon'
    }
  },
  '10': {
    id: 10,
    code: 'multisport_raid',
    display: 'multi_activity',
    names: {
      en: 'Adventure race',
      fr: 'Raid',
      de: 'Abenteuerrennen',
      es: 'Carrera de aventura',
      pt: 'Corrida de aventura',
      pl: 'Rajd przygodowy',
      'pt-BR': 'Corrida de aventura',
      sv: 'Äventyrslopp',
      zh: '探险比赛',
      ko: '어드벤처 레이스',
      ja: 'アドベンチャーレース',
      ru: 'Приключенческая гонка',
      th: 'Adventure race',
      it: "Gara d'avventura",
      nl: 'Avontuurlijke race',
      tr: 'Macera yarışı',
      id: 'Perlombaan petualangan'
    },
    permalinks: {
      en: 'adventure-race',
      fr: 'raid',
      de: 'abenteuerrennen',
      es: 'carrera-de-aventura',
      pt: 'corrida-de-aventura',
      pl: 'rajd-przygodowy',
      'pt-BR': 'corrida-de-aventura',
      sv: 'aventyrslopp',
      ru: '',
      it: 'gara-d-avventura',
      nl: 'avontuurlijke-race',
      tr: 'macera-yarisi'
    },
    order: 14,
    group: null,
    type: 'type_multi_sport',
    isFilter: false,
    titles: {
      en: 'Adventure race',
      fr: "Course d'aventure",
      de: 'Abenteuer Rennen',
      es: 'Carrera de aventura',
      pt: 'Corrida de aventura',
      pl: 'Rajd przygodowy',
      'pt-BR': 'Corrida de aventura',
      sv: 'Äventyrslopp',
      zh: '探险比赛',
      ko: '어드벤처 레이스',
      ja: 'アドベンチャーレース',
      ru: 'Приключенческая гонка',
      th: 'การแข่งขันผจญภัย',
      it: "Gara d'avventura",
      nl: 'Avontuurlijke race',
      tr: 'Macera yarışı',
      id: 'Perlombaan petualangan'
    }
  },
  '11': {
    id: 11,
    code: 'cycling',
    display: 'single_activity',
    names: {
      en: 'Cycling',
      fr: 'Cyclisme',
      de: 'Radfahren',
      es: 'Ciclismo',
      pt: 'Ciclismo',
      pl: 'Kolarstwo',
      'pt-BR': 'Ciclismo',
      sv: 'Cykling',
      zh: '自行车',
      ko: '사이클링',
      ja: 'サイクリング',
      ru: 'Велоспорт',
      th: 'Cycling',
      it: 'Ciclismo',
      nl: 'Fietsen',
      tr: 'Bisiklet',
      id: 'Bersepeda'
    },
    permalinks: {
      en: 'cycling',
      fr: 'cyclisme',
      de: 'radfahren',
      es: 'ciclismo',
      pt: 'ciclismo',
      pl: 'kolarstwo',
      'pt-BR': 'ciclismo',
      sv: 'cykling',
      ru: '',
      it: 'ciclismo',
      nl: 'fietsen',
      tr: 'bisiklet'
    },
    order: 4,
    group: 'group_cycling',
    type: 'type_cycling',
    isFilter: true,
    titles: {
      en: 'Cycling races',
      fr: 'Courses cyclistes',
      de: 'Radrennen',
      es: 'Carreras ciclistas',
      pt: 'Corridas de ciclismo',
      pl: 'Wyścigi kolarskie',
      'pt-BR': 'Corridas de ciclismo',
      sv: 'Cykeltävlingar',
      zh: '自行车赛',
      ko: '사이클링 레이스',
      ja: 'サイクリングレース',
      ru: 'Велосипедные гонки',
      th: 'Cycling races',
      it: 'Gare di ciclismo',
      nl: 'Wielerwedstrijden',
      tr: 'Bisiklet yarışları',
      id: 'Balapan sepeda'
    }
  },
  '12': {
    id: 12,
    code: 'swim_run',
    display: 'single_activity',
    names: {
      en: 'Swimrun',
      fr: 'Swimrun',
      de: 'Swimrun',
      es: 'Swimrun',
      pt: 'Swimrun',
      pl: 'Swimrun',
      'pt-BR': 'Swimrun',
      sv: 'Swimrun',
      zh: '游泳跑',
      ko: '스윔런',
      ja: 'スイムラン',
      ru: 'Свимран',
      th: 'Swimrun',
      it: 'Swimrun',
      nl: 'Zwemloop',
      tr: 'Yüzme koşusu',
      id: 'Lari-renang'
    },
    permalinks: {
      en: 'swimrun',
      fr: 'swimrun',
      de: 'swimrun',
      es: 'swimrun',
      pt: 'swimrun',
      pl: 'swimrun',
      'pt-BR': 'swimrun',
      sv: 'swimrun',
      ru: '',
      it: 'swimrun',
      nl: 'zwemloop',
      tr: 'yuzme-kosusu'
    },
    order: 10,
    group: 'group_sr',
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Swimruns',
      fr: 'Swimruns',
      de: 'Swimruns',
      es: 'Carreras a nado',
      pt: 'Corridas de natação',
      pl: 'Swimruns',
      'pt-BR': 'Corridas de natação',
      sv: 'Swimruns',
      zh: '游泳跑',
      ko: '스윔런',
      ja: 'スイムラン',
      ru: 'Свимраны ',
      th: 'Swimruns',
      it: 'Swimruns',
      nl: 'Zwemlopen',
      tr: 'Yüzme Koşuları',
      id: 'berenang dan berlari'
    }
  },
  '13': {
    id: 13,
    code: 'aquathlon',
    display: 'multi_activity',
    names: {
      en: 'Aquathlon',
      fr: 'Aquathlon',
      de: 'Aquathlon',
      es: 'Acuatlón',
      pt: 'Aquatlo',
      pl: 'Aquathlon\n\n',
      'pt-BR': 'Aquatlo',
      sv: 'Aquathlon',
      zh: '水上运动',
      ko: '아쿠아슬론',
      ja: 'アクアスロン',
      ru: 'Акватлон',
      th: 'Aquathlon',
      it: 'Aquathlon',
      nl: 'Aquathlon',
      tr: 'Akuatlon',
      id: 'Aquathlon'
    },
    permalinks: {
      en: 'aquathlon',
      fr: 'aquathlon',
      de: 'aquathlon',
      es: 'acuatlon',
      pt: 'aquatlo',
      pl: 'aquathlon',
      'pt-BR': 'aquatlo',
      sv: 'aquathlon',
      ru: '',
      it: 'aquathlon',
      nl: 'aquathlon',
      tr: 'akuatlon'
    },
    order: 12,
    group: null,
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Aquathlons',
      fr: 'Aquathlons',
      de: 'Aquathlons',
      es: 'Acuatlones',
      pt: 'Aquatlos',
      pl: 'Aquathlony',
      'pt-BR': 'Aquathlons',
      sv: 'Aquathlons',
      zh: '水上运动',
      ko: '아쿠아슬론',
      ja: 'アクアスロン',
      ru: 'Акватлоны',
      th: 'Aquathlons',
      it: 'Acquathlon',
      nl: 'Aquathlons',
      tr: 'Aquatlonlar',
      id: 'Aquathlon'
    }
  },
  '14': {
    id: 14,
    code: 'dog_run',
    display: 'single_activity',
    names: {
      en: 'Dog run',
      fr: 'Canicross',
      de: 'Dog run',
      es: 'Dog run',
      pt: 'Canicross',
      pl: 'Canicross',
      'pt-BR': 'Canicross',
      sv: 'Dog run',
      zh: '狗狗赛',
      ko: '도그런',
      ja: 'ドッグマラソン',
      ru: 'Бег с собакой',
      th: 'Dog run',
      it: 'Dog run',
      nl: 'Hondenren',
      tr: 'Köpek koşusu',
      id: 'Area bermain anjing'
    },
    permalinks: {
      en: 'dog-run',
      fr: 'canicross',
      de: 'dog-run',
      es: 'dog-run',
      pt: 'canicross',
      pl: 'canicross',
      'pt-BR': 'canicross',
      sv: 'dog-run',
      ru: '',
      it: 'dog-run',
      nl: 'hondenren',
      tr: 'kopek-kosusu'
    },
    order: 3,
    group: 'group_run',
    type: 'type_run',
    isFilter: true,
    titles: {
      en: 'Dog runs',
      fr: 'Courses canicross',
      de: 'Dog Runs',
      es: 'Carreras de canicross',
      pt: 'Corridas para cães',
      pl: 'Canicross',
      'pt-BR': 'Canicross',
      sv: 'Dog runs',
      zh: '赛狗',
      ko: '도그런',
      ja: 'ドッグマラソン',
      ru: 'Забеги с собаками',
      th: 'Dog runs',
      it: 'Eventi di Dog Run',
      nl: 'Hondenrennen',
      tr: 'Köpek koşuları',
      id: 'Area bermain anjing'
    }
  },
  '15': {
    id: 15,
    code: 'wheelchair',
    display: 'single_activity',
    names: {
      en: 'Wheelchair',
      fr: 'Fauteuil roulant',
      de: 'Rollstuhl',
      es: 'Silla de ruedas',
      pt: 'Cadeira de rodas',
      pl: 'Na wózkach',
      'pt-BR': 'Cadeira de rodas',
      sv: 'Rullstol',
      zh: '轮椅',
      ko: '휠체어',
      ja: '車椅子',
      ru: 'Инвалидное кресло',
      th: 'รถเข็น',
      it: 'Sedia a rotelle',
      nl: 'Rolstoel',
      tr: 'Tekerlekli sandalye',
      id: 'Kursi roda'
    },
    permalinks: {
      en: 'wheelchair',
      fr: 'fauteuil-roulant',
      de: 'rollstuhl',
      es: 'silla-de-ruedas',
      pt: 'cadeira-de-rodas',
      pl: 'na-wozkach',
      'pt-BR': 'cadeira-de-rodas',
      sv: 'rullstol',
      ru: '',
      it: 'sedia-a-rotelle',
      nl: 'rolstoel',
      tr: 'tekerlekli-sandalye'
    },
    order: 26,
    group: 'group_run',
    type: 'type_other',
    isFilter: true,
    titles: {
      en: 'Wheelchair races',
      fr: 'Courses en fauteuil',
      de: 'Rollstuhlrennen',
      es: 'Carreras en silla de ruedas',
      pt: 'Corridas em cadeira de rodas',
      pl: 'Wyścigi na wózkach',
      'pt-BR': 'Corridas em cadeira de rodas',
      sv: 'Tävlingar för rullstolsanvändare',
      zh: '轮椅比赛',
      ko: '휠체어 레이스',
      ja: '車いすレース',
      ru: 'Гонки на инвалидных колясках',
      th: 'Wheelchair races',
      it: 'Gare in carrozzina',
      nl: 'Rolstoel races',
      tr: 'Tekerlekli sandalye yarışları',
      id: 'Balapan kursi roda'
    }
  },
  '16': {
    id: 16,
    code: 'orienteering',
    display: 'single_activity',
    names: {
      en: 'Orienteering',
      fr: "Course d'orientation",
      de: 'Orientierungslauf',
      es: 'Orientación',
      pt: 'Orientação',
      pl: 'Biegi na orientację',
      'pt-BR': 'Orientação',
      sv: 'Orientering',
      zh: '定向运动',
      ko: '오리엔티어링',
      ja: 'オリエンテーリング',
      ru: 'Ориентирование',
      th: 'Orienteering',
      it: 'Orienteering',
      nl: 'Oriëntatieloop',
      tr: 'Oryantiring',
      id: 'Orienteering'
    },
    permalinks: {
      en: 'orienteering',
      fr: 'course-d-orientation',
      de: 'orientierungslauf',
      es: 'orientacion',
      pt: 'orientacao',
      pl: 'biegi-na-orientacje',
      'pt-BR': 'orientacao',
      sv: 'orientering',
      ru: '',
      it: 'orienteering',
      nl: 'orientatieloop',
      tr: 'oryantiring'
    },
    order: 25,
    group: null,
    type: 'type_other',
    isFilter: true,
    titles: {
      en: 'Orienteering races',
      fr: "Courses d'orientation",
      de: 'Orientierungslaufrennen',
      es: 'Carreras de orientación',
      pt: 'Corridas de orientação',
      pl: 'Biegi na orientację',
      'pt-BR': 'Corridas de orientação',
      sv: 'Orienteringstävlingar',
      zh: '野外定向赛',
      ko: '오리엔티어링 레이스',
      ja: 'オリエンテーリング・レース',
      ru: 'Гонки по спортивному ориентированию',
      th: 'Orienteering races',
      it: 'Gare di orienteering',
      nl: 'Oriëntatieloop wedstrijden',
      tr: 'Oryantiring yarışları',
      id: 'Perlombaan Orienteering'
    }
  },
  '17': {
    id: 17,
    code: 'swimming',
    display: 'single_activity',
    names: {
      en: 'Swimming',
      fr: 'Natation',
      de: 'Schwimmen',
      es: 'Natación',
      pt: 'Natação',
      pl: 'Pływanie',
      'pt-BR': 'Natação',
      sv: 'Simning',
      zh: '游泳',
      ko: '수영',
      ja: '水泳',
      ru: 'Плавание',
      th: 'Swimming',
      it: 'Nuoto',
      nl: 'Zwemmen',
      tr: 'Yüzme',
      id: 'Berenang'
    },
    permalinks: {
      en: 'swimming',
      fr: 'natation',
      de: 'schwimmen',
      es: 'natacion',
      pt: 'natacao',
      pl: 'plywanie',
      'pt-BR': 'natacao',
      sv: 'simning',
      ru: '',
      it: 'nuoto',
      nl: 'zwemmen',
      tr: 'yuzme'
    },
    order: 15,
    group: null,
    type: 'type_water',
    isFilter: true,
    titles: {
      en: 'Swims',
      fr: 'Epreuves de natation',
      de: 'Schwimmen',
      es: 'Pruebas de nado',
      pt: 'Natação',
      pl: 'Pływanie',
      'pt-BR': 'Natação',
      sv: 'Simning',
      zh: '游泳',
      ko: '수영',
      ja: '水泳',
      ru: 'Заплывы',
      th: '\nSwims',
      it: 'Nuoto',
      nl: 'Zwemt',
      tr: 'Yüzüş',
      id: 'Berenang'
    }
  },
  '18': {
    id: 18,
    code: 'inline_skating',
    display: 'single_activity',
    names: {
      en: 'Inline skating',
      fr: 'Roller en ligne',
      de: 'Inline-skaten',
      es: 'Patinaje en línea',
      pt: 'Patins em linha',
      pl: 'Jazda na rolkach',
      'pt-BR': 'Patinação inline',
      sv: 'Inlines',
      zh: '直排轮滑',
      ko: '인라인 스케이트',
      ja: 'インラインスケート',
      ru: 'Катание на роликовых коньках',
      th: 'Inline skating',
      it: 'Pattinaggio in linea',
      nl: 'Inline skaten',
      tr: 'Sıralı paten',
      id: 'Sepatu roda inline'
    },
    permalinks: {
      en: 'inline-skating',
      fr: 'roller-en-ligne',
      de: 'inline-skaten',
      es: 'patinaje-en-linea',
      pt: 'patins-em-linha',
      pl: 'jazda-na-rolkach',
      'pt-BR': 'patinacao-inline',
      sv: 'inlines',
      ru: '',
      it: 'pattinaggio-in-linea',
      nl: 'inline-skaten',
      tr: 'sirali-paten'
    },
    order: 27,
    group: null,
    type: 'type_other',
    isFilter: false,
    titles: {
      en: 'Inline skating races',
      fr: 'Courses de roller en ligne',
      de: 'Inline-Skating-Rennen',
      es: 'Carreras de patinaje en línea',
      pt: 'Corridas de patinagem em linha',
      pl: 'Wyścigi na rolkach',
      'pt-BR': 'Corridas de patinação inline',
      sv: 'Inlinetävlingar',
      zh: '直排轮滑比赛',
      ko: '인라인 스케이팅 레이스',
      ja: 'インラインスケートレース',
      ru: 'Гонки на роликовых коньках',
      th: 'Inline skating races',
      it: 'Gare di pattinaggio in linea',
      nl: 'Inline skate wedstrijden',
      tr: 'Sıralı paten yarışları',
      id: 'Perlombaan seluncur indah (inline skating)'
    }
  },
  '19': {
    id: 19,
    code: 'sup',
    display: 'single_activity',
    names: {
      en: 'Standup paddling',
      fr: 'Standup paddle',
      de: 'Stand-up-paddeln',
      es: 'Paddle surf',
      pt: 'Standup paddle',
      pl: 'Pływanie na desce sup',
      'pt-BR': 'Standup paddling',
      sv: 'Sup',
      zh: '立式划艇',
      ko: '스탠드업 패들링',
      ja: 'スタンドアップパドル',
      ru: 'Гребля стоя',
      th: 'Standup paddling',
      it: 'Standup paddling',
      nl: 'Standup paddling',
      tr: 'Ayakta kürek çekme',
      id: 'Papan dayung berdiri'
    },
    permalinks: {
      en: 'standup-paddling',
      fr: 'standup-paddle',
      de: 'stand-up-paddeln',
      es: 'paddle-surf',
      pt: 'standup-paddle',
      pl: 'plywanie-na-desce-sup',
      'pt-BR': 'standup-paddling',
      sv: 'sup',
      ru: '',
      it: 'standup-paddling',
      nl: 'standup-paddling',
      tr: 'ayakta-kurek-cekme'
    },
    order: 17,
    group: null,
    type: 'type_water',
    isFilter: false,
    titles: {
      en: 'Standup paddling races',
      fr: 'Epreuves de standup paddle',
      de: 'Standup Paddling Rennen',
      es: 'Carreras de standup paddle',
      pt: 'Regatas de standup paddling',
      pl: 'Wyścigi SUP',
      'pt-BR': 'Corridas de standup',
      sv: 'Tävlingar i standup-paddling',
      zh: '立式划艇比赛',
      ko: '스탠드업 패들링 레이스',
      ja: 'スタンドアップパドリングレース',
      ru: 'Гонки по гребле стоя',
      th: 'Standup padding races',
      it: 'Gare di stand up paddling',
      nl: 'Standup paddling wedstrijden',
      tr: 'Ayakta kürek çekme yarışları',
      id: 'Perlombaan papan dayung berdiri'
    }
  },
  '20': {
    id: 20,
    code: 'aquabike',
    display: 'multi_activity',
    names: {
      en: 'Aquabike',
      fr: 'Aquabike',
      de: 'Aquabike',
      es: 'Aquabike',
      pt: 'Aquabike',
      pl: 'Aquabike',
      'pt-BR': 'Aquabike',
      sv: 'Aquabike',
      zh: '水上自行车',
      ko: '아쿠아바이크',
      ja: 'アクアバイク',
      ru: 'Аквабайк',
      th: 'Aquabike',
      it: 'Aquabike',
      nl: 'Aquabike',
      tr: 'Su bisikleti',
      id: 'Jet ski'
    },
    permalinks: {
      en: 'aquabike',
      fr: 'aquabike',
      de: 'aquabike',
      es: 'aquabike',
      pt: 'aquabike',
      pl: 'aquabike',
      'pt-BR': 'aquabike',
      sv: 'aquabike',
      ru: '',
      it: 'aquabike',
      nl: 'aquabike',
      tr: 'su-bisikleti'
    },
    order: 11,
    group: null,
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Aquabike races',
      fr: "Courses d'aquabike",
      de: 'Aquabike Rennen',
      es: 'Carreras de aquabike',
      pt: 'Corridas de Aquabike',
      pl: 'Wyścigi aquabike',
      'pt-BR': 'Corridas de Aquabike',
      sv: 'Aquabike-tävlingar',
      zh: '水上自行车赛',
      ko: '아쿠아바이크 레이스',
      ja: 'アクアバイクレース',
      ru: 'Гонки на аквабайках',
      th: 'Aquabike races',
      it: 'Gare di aquabike',
      nl: 'Aquafiets races',
      tr: 'Su bisikleti yarışları',
      id: 'Perlombaan jet ski'
    }
  },
  '21': {
    id: 21,
    code: 'rafting',
    display: 'single_activity',
    names: {
      en: 'Rafting',
      fr: 'Rafting',
      de: 'Rafting',
      es: 'Rafting',
      pt: 'Rafting',
      pl: 'Rafting',
      'pt-BR': 'Rafting',
      sv: 'Forsränning',
      zh: '漂流',
      ko: '래프팅',
      ja: 'ラフティング',
      ru: 'Рафтинг',
      th: 'Rafting',
      it: 'Rafting',
      nl: 'Raften',
      tr: 'Rafting',
      id: 'Arung jeram'
    },
    permalinks: {
      en: 'rafting',
      fr: 'rafting',
      de: 'rafting',
      es: 'rafting',
      pt: 'rafting',
      pl: 'rafting',
      'pt-BR': 'rafting',
      sv: 'forsranning',
      ru: '',
      it: 'rafting',
      nl: 'raften',
      tr: 'rafting'
    },
    order: 32,
    group: null,
    type: 'type_water',
    isFilter: false,
    titles: {
      en: 'Rafting races',
      fr: 'Epreuves de rafting',
      de: 'Rafting Rennen',
      es: 'Carreras de rafting',
      pt: 'Corridas de rafting',
      pl: 'Rafting',
      'pt-BR': 'Rafting',
      sv: 'Rafting-tävlingar',
      zh: '漂流比赛',
      ko: '래프팅 레이스',
      ja: 'ラフティングレース',
      ru: 'Гонки на рафтах',
      th: 'Rafting races',
      it: 'Gare di rafting',
      nl: 'Rafting wedstrijden',
      tr: 'Rafting yarışları',
      id: 'Perlombaan arung jeram'
    }
  },
  '22': {
    id: 22,
    code: 'alpine_skiing',
    display: 'single_activity',
    names: {
      en: 'Alpine skiing',
      fr: 'Ski alpin',
      de: 'Alpin ski',
      es: 'Esquí alpino',
      pt: 'Ski alpino',
      pl: 'Narciarstwo alpejskie',
      'pt-BR': 'Esqui alpino',
      sv: 'Alpin skidåkning',
      zh: '高山滑雪',
      ko: '알파인 스키',
      ja: 'アルペンスキー',
      ru: 'Горные лыжи',
      th: 'Alpine skiing',
      it: 'Sci alpino',
      nl: 'Alpineskiën',
      tr: 'Alp disiplini kayak',
      id: 'Ski alpine'
    },
    permalinks: {
      en: 'alpine-skiing',
      fr: 'ski-alpin',
      de: 'alpin-ski',
      es: 'esqui-alpino',
      pt: 'ski-alpino',
      pl: 'narciarstwo-alpejskie',
      'pt-BR': 'esqui-alpino',
      sv: 'alpin-skidakning',
      ru: '',
      it: 'sci-alpino',
      nl: 'alpineskien',
      tr: 'alp-disiplini-kayak'
    },
    order: 29,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Alpine skiing races',
      fr: 'Courses de ski alpin',
      de: 'Alpine Skirennen',
      es: 'Carreras de esquí alpino',
      pt: 'Corridas de esqui alpino',
      pl: 'Wyścigi w narciarstwie alpejskim',
      'pt-BR': 'Esqui alpino',
      sv: 'Alpina skidtävlingar',
      zh: '高山滑雪比赛',
      ko: '알파인 스키 레이스',
      ja: 'アルペンスキーレース',
      ru: 'Горнолыжные гонки',
      th: 'Alpine skiing races',
      it: 'Gare di sci alpino',
      nl: 'Alpine skiwedstrijden',
      tr: 'Alp disiplini kayak yarışları',
      id: 'Perlombaan ski alpine'
    }
  },
  '23': {
    id: 23,
    code: 'telemark',
    display: 'single_activity',
    names: {
      en: 'Telemark',
      fr: 'Telemark',
      de: 'Telemark',
      es: 'Telemark',
      pt: 'Telemark',
      pl: 'Telemark',
      'pt-BR': 'Telemark',
      sv: 'Telemark',
      zh: 'Telemark',
      ko: '텔레마크 스키',
      ja: 'テレマーク',
      ru: 'Телемарк',
      th: 'Telemark',
      it: 'Telemark',
      nl: 'Telemark',
      tr: 'Telemark',
      id: 'Telemark'
    },
    permalinks: {
      en: 'telemark',
      fr: 'telemark',
      de: 'telemark',
      es: 'telemark',
      pt: 'telemark',
      pl: 'telemark',
      'pt-BR': 'telemark',
      sv: 'telemark',
      ru: '',
      it: 'telemark',
      nl: 'telemark',
      tr: 'telemark'
    },
    order: 30,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Telemark races',
      fr: 'Courses de télémark',
      de: 'Telemark Rennen',
      es: 'Carreras de telemark',
      pt: 'Corridas de telemark',
      pl: 'Wyścigi narciarskie',
      'pt-BR': 'Corridas de ski',
      sv: 'Telemarkstävlingar',
      zh: 'Telemark 赛事',
      ko: '텔레마크 레이스',
      ja: 'テレマークレース',
      ru: 'Гонки в дисциплине телемарк',
      th: 'Telemark races',
      it: 'Gare di telemark',
      nl: 'Telemark wedstrijden',
      tr: 'Telemark yarışları',
      id: 'Perlombaan Telemark'
    }
  },
  '24': {
    id: 24,
    code: 'xc_skiing',
    display: 'single_activity',
    names: {
      en: 'Cross-country skiing',
      fr: 'Ski de fond',
      de: 'Skilanglauf',
      es: 'Esquí de fondo',
      pt: 'Ski cross-country',
      pl: 'Narciarstwo biegowe',
      'pt-BR': 'Esqui cross-country',
      sv: 'Längdskidåkning',
      zh: '越野滑雪',
      ko: '크로스컨트리 스키',
      ja: 'クロスカントリースキー',
      ru: 'Беговые лыжи',
      th: 'Cross-country skiing',
      it: 'Cross-country skiing',
      nl: 'Langlaufen',
      tr: 'Kros kayağı',
      id: 'Ski lintas alam'
    },
    permalinks: {
      en: 'cross-country-skiing',
      fr: 'ski-de-fond',
      de: 'skilanglauf',
      es: 'esqui-de-fondo',
      pt: 'ski-cross-country',
      pl: 'narciarstwo-biegowe',
      'pt-BR': 'esqui-cross-country',
      sv: 'langdskidakning',
      ru: '',
      it: 'cross-country-skiing',
      nl: 'langlaufen',
      tr: 'kros-kayagi'
    },
    order: 21,
    group: 'group_xcs',
    type: 'type_winter',
    isFilter: true,
    titles: {
      en: 'Cross-country skiing',
      fr: 'Epreuves de ski nordique',
      de: 'Skilanglauf',
      es: 'Esquí de fondo',
      pt: 'Esqui de fundo',
      pl: 'Narciarstwo biegowe',
      'pt-BR': 'Esqui cross-country',
      sv: 'Längdskidåkning',
      zh: '越野滑雪',
      ko: '크로스컨트리 스키',
      ja: 'クロスカントリースキー',
      ru: 'Беговые лыжи',
      th: 'Cross-country skiing',
      it: 'Cross-country skiing',
      nl: 'Langlaufen',
      tr: 'Kros kayağı',
      id: 'Ski lintas alam'
    }
  },
  '25': {
    id: 25,
    code: 'ski_orienteering',
    display: 'single_activity',
    names: {
      en: 'Ski orienteering',
      fr: "Ski d'orientation",
      de: 'Skiorientierungslauf',
      es: 'Esquí de orientación',
      pt: 'Orientação em ski',
      pl: 'Biegi na orientację na nartach',
      'pt-BR': 'Orientação em esqui',
      sv: 'Skidorientering',
      zh: '滑雪定向',
      ko: '스키 오리엔티어링',
      ja: 'スキー・オリエンテーリング',
      ru: 'Ориентирование на лыжах',
      th: 'Ski orienteering',
      it: 'Ski orienteering',
      nl: 'Skiën',
      tr: 'Kayak oryantiringi',
      id: 'Orienteering ski'
    },
    permalinks: {
      en: 'ski-orienteering',
      fr: 'ski-d-orientation',
      de: 'skiorientierungslauf',
      es: 'esqui-de-orientacion',
      pt: 'orientacao-em-ski',
      pl: 'biegi-na-orientacje-na-nartach',
      'pt-BR': 'orientacao-em-esqui',
      sv: 'skidorientering',
      ru: '',
      it: 'ski-orienteering',
      nl: 'skien',
      tr: 'kayak-oryantiringi'
    },
    order: 32,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Ski orienteering races',
      fr: "Epreuves de ski d'orientation",
      de: 'Ski-Orientierungslauf',
      es: 'Pruebas de esquí de orientación',
      pt: 'Corridas de orientação em esqui',
      pl: 'Narciarskie biegi na orientację',
      'pt-BR': 'Corridas de orientação para esqui',
      sv: 'Skidorienteringstävlingar',
      zh: '滑雪定向赛',
      ko: '스키 오리엔티어링 레이스',
      ja: 'スキー・オリエンテーリング・レース',
      ru: 'Гонки по ориентированию на лыжах',
      th: 'Ski orienteering races',
      it: 'Gare di ski orienteering',
      nl: 'Ski oriëntatieloop wedstrijden',
      tr: 'Kayak oryantiring yarışları',
      id: 'Perlombaan ski orienteering'
    }
  },
  '26': {
    id: 26,
    code: 'skimo',
    display: 'single_activity',
    names: {
      en: 'Ski mountaineering',
      fr: 'Ski alpinisme',
      de: 'Skibergsteigen',
      es: 'Esquí de montaña',
      pt: 'Ski de montanha',
      pl: 'Ski-alpinizm',
      'pt-BR': 'Esqui de montanha',
      sv: 'Skidåkning och bergsbestigning',
      zh: '滑雪登山',
      ko: '스키 등산',
      ja: 'スキーマウンテニアリング',
      ru: 'Горнолыжный альпинизм',
      th: 'Ski mountaineering',
      it: 'Sci alpinismo',
      nl: 'Alpinisme',
      tr: 'Dağ kayağı',
      id: 'Ski lintas alam gunung'
    },
    permalinks: {
      en: 'ski-mountaineering',
      fr: 'ski-alpinisme',
      de: 'skibergsteigen',
      es: 'esqui-de-montana',
      pt: 'ski-de-montanha',
      pl: 'ski-alpinizm',
      'pt-BR': 'esqui-de-montanha',
      sv: 'skidakning-och-bergsbestigning',
      ru: '',
      it: 'sci-alpinismo',
      nl: 'alpinisme',
      tr: 'dag-kayagi'
    },
    order: 20,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Ski mountaineering races',
      fr: 'Courses de ski alpinisme',
      de: 'Skibergsteig Rennen',
      es: 'Carreras de esquí de montaña',
      pt: 'Corridas de esqui de montanha',
      pl: 'Narciarskie wyścigi górskie',
      'pt-BR': 'Ski mountaineering',
      sv: 'Tävlingar i alpin skidåkning',
      zh: '滑雪登山比赛',
      ko: '스키 등산 레이스',
      ja: 'スキーマウンテニアリングレース',
      ru: 'Лыжные гонки по альпинизму',
      th: 'Ski mountaineering races',
      it: 'Gare di sci alpinismo',
      nl: 'Ski alpinisme wedstrijden',
      tr: 'Dağ kayağı yarışları',
      id: 'Lomba Ski Gunung'
    }
  },
  '27': {
    id: 27,
    code: 'ski_jumping',
    display: 'single_activity',
    names: {
      en: 'Ski jumping',
      fr: 'Saut à ski',
      de: 'Skispringen',
      es: 'Salto de esquí',
      pt: 'Salto de ski',
      pl: 'Skoki narciarskie',
      'pt-BR': 'Salto de esqui',
      sv: 'Skidhoppning',
      zh: '跳台滑雪',
      ko: '스키 점프',
      ja: 'スキージャンプ',
      ru: 'Прыжки с трамплина',
      th: 'Ski jumping',
      it: 'Ski jumping',
      nl: 'Schansspringen',
      tr: 'Kayakla atlama',
      id: 'Lompat ski'
    },
    permalinks: {
      en: 'ski-jumping',
      fr: 'saut-a-ski',
      de: 'skispringen',
      es: 'salto-de-esqui',
      pt: 'salto-de-ski',
      pl: 'skoki-narciarskie',
      'pt-BR': 'salto-de-esqui',
      sv: 'skidhoppning',
      ru: '',
      it: 'ski-jumping',
      nl: 'schansspringen',
      tr: 'kayakla-atlama'
    },
    order: 31,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Ski jumping races',
      fr: 'Courses de saut à ski',
      de: 'Skisprungrennen',
      es: 'Carreras de saltos de esquí',
      pt: 'Corridas de saltos de esqui',
      pl: 'Skoki narciarskie',
      'pt-BR': 'Ski jumping',
      sv: 'Tävlingar i backhoppning',
      zh: '跳台滑雪比赛',
      ko: '스키점프 레이스',
      ja: 'スキージャンプレース',
      ru: 'Прыжки с трамплина',
      th: 'Ski jumping races',
      it: 'Gare di ski jumping',
      nl: 'Skispringwedstrijden',
      tr: 'Kayakla atlama yarışları',
      id: 'Perlombaan lompat ski'
    }
  },
  '28': {
    id: 28,
    code: 'cyclo_cross',
    display: 'single_activity',
    names: {
      en: 'Cyclo-cross',
      fr: 'Cyclo-cross',
      de: 'Cyclo-cross',
      es: 'Ciclocross',
      pt: 'Ciclocross',
      pl: 'Kolarstwo przełajowe',
      'pt-BR': 'Ciclocross',
      sv: 'Cyklo-cross',
      zh: '越野自行车',
      ko: '사이클로-크로스',
      ja: 'シクロクロス',
      ru: 'Велокросс',
      th: '\ncyclo-cross',
      it: 'Ciclocross',
      nl: 'Veldrijden',
      tr: 'Bisiklet-krosu',
      id: 'Sepeda lintas alam'
    },
    permalinks: {
      en: 'cyclo-cross',
      fr: 'cyclo-cross',
      de: 'cyclo-cross',
      es: 'ciclocross',
      pt: 'ciclocross',
      pl: 'kolarstwo-przelajowe',
      'pt-BR': 'ciclocross',
      sv: 'cyklo-cross',
      ru: '',
      it: 'ciclocross',
      nl: 'veldrijden',
      tr: 'bisiklet-krosu'
    },
    order: 7,
    group: 'group_cycling',
    type: 'type_cycling',
    isFilter: false,
    titles: {
      en: 'Cyclo-cross races',
      fr: 'Courses de cyclo-cross',
      de: 'Cyclocross Rennen',
      es: 'Carreras de ciclocross',
      pt: 'Corridas de ciclocross',
      pl: 'Wyścigi kolarstwa przełajowego',
      'pt-BR': 'Corridas de ciclocross',
      sv: 'Cykelcross-tävlingar',
      zh: '公路越野赛',
      ko: '사이클로크로스 레이스',
      ja: 'シクロクロスレース',
      ru: 'Гонки по циклокроссу',
      th: '\nCyclo-cross races',
      it: 'Gare di ciclocross',
      nl: 'Veldrijden',
      tr: 'Bisikletli koşu yarışları',
      id: 'Perlombaan sepeda lintas alam'
    }
  },
  '29': {
    id: 29,
    code: 'nordic_ice_skating',
    display: 'single_activity',
    names: {
      en: 'Nordic ice skating',
      fr: 'Patinage nordique',
      de: 'Nordic ice skating',
      es: 'Patinaje nórdico sobre hielo',
      pt: 'Patinagem no gelo ',
      pl: 'Łyżwiarstwo nordyckie',
      'pt-BR': 'Patinação no gelo nórdica',
      sv: 'Skridskoåkning',
      zh: '北欧滑冰',
      ko: '노르딕 아이스 스케이팅',
      ja: 'ノルディックアイススケート',
      ru: 'Скандинавское катание на коньках',
      th: 'Nordic ice skating',
      it: 'Pattinaggio nordico',
      nl: 'Noords schaatsen',
      tr: 'Nordik buz pateni',
      id: 'Seluncur es ala nordik'
    },
    permalinks: {
      en: 'nordic-ice-skating',
      fr: 'patinage-nordique',
      de: 'nordic-ice-skating',
      es: 'patinaje-nordico-sobre-hielo',
      pt: 'patinagem-no-gelo',
      pl: 'lyzwiarstwo-nordyckie',
      'pt-BR': 'patinacao-no-gelo-nordica',
      sv: 'skridskoakning',
      ru: '',
      it: 'pattinaggio-nordico',
      nl: 'noords-schaatsen',
      tr: 'nordik-buz-pateni'
    },
    order: 19,
    group: null,
    type: 'type_winter',
    isFilter: false,
    titles: {
      en: 'Nordic ice skating races',
      fr: 'Courses de patinage nordique',
      de: 'Nordic Ice Skating Rennen',
      es: 'Carreras de patinaje nórdico',
      pt: 'Corridas nórdicas de patinagem no gelo',
      pl: 'Wyścigi w łyżwiarstwie klasycznym',
      'pt-BR': 'Corridas de patinação nordico',
      sv: 'Långfärdsskridskotävlingar',
      zh: '北欧滑冰比赛',
      ko: '노르딕 아이스 스케이팅 레이스',
      ja: 'ノルディックアイススケートレース',
      ru: 'Скандинавские конькобежные гонки',
      th: 'Nordic ice skating races',
      it: 'Gare di pattinaggio nordico',
      nl: 'Noordse schaatswedstrijden',
      tr: 'Nordik buz pateni yarışları',
      id: 'Perlombaan seluncur es Nordik'
    }
  },
  '30': {
    id: 30,
    code: 'trail_run',
    display: 'single_activity',
    names: {
      en: 'Trail running',
      fr: 'Trail',
      de: 'Trailrunning',
      es: 'Trail running',
      pt: 'Corrida em trilhos',
      pl: 'Bieganie w terenie',
      'pt-BR': 'Corrida em trilha',
      sv: 'Trail',
      zh: '越野跑',
      ko: '트레일 러닝',
      ja: 'トレイルランニング',
      ru: 'Бег по тропам (трейл бег)',
      th: 'Trail running',
      it: 'Trail running',
      nl: 'Hardlopen',
      tr: 'Patika koşusu',
      id: 'Lari lintas alam'
    },
    permalinks: {
      en: 'trail-running',
      fr: 'trail',
      de: 'trailrunning',
      es: 'trail-running',
      pt: 'corrida-em-trilhos',
      pl: 'bieganie-w-terenie',
      'pt-BR': 'corrida-em-trilha',
      sv: 'trail',
      ru: '',
      it: 'trail-running',
      nl: 'hardlopen',
      tr: 'patika-kosusu'
    },
    order: 2,
    group: 'group_run',
    type: 'type_run',
    isFilter: true,
    titles: {
      en: 'Trail running races',
      fr: 'Courses de trail',
      de: 'Trailrunning Rennen',
      es: 'Carreras de trail running',
      pt: 'Corridas de trail running',
      pl: 'Wyścigi w biegach przełajowych',
      'pt-BR': 'Corridas em trilha',
      sv: 'Terränglöptävlingar',
      zh: '越野赛',
      ko: '트레일 러닝 레이스',
      ja: 'トレイルランニングレース',
      ru: 'Трейл забеги',
      th: 'Trail running races',
      it: 'Gare di trail running',
      nl: 'Trail hardloopwedstrijden',
      tr: 'Patika koşu yarışları',
      id: 'Perlombaan lari lintas alam'
    }
  },
  '31': {
    id: 31,
    code: 'gravel',
    display: 'single_activity',
    names: {
      en: 'Gravel biking',
      fr: 'Gravel',
      de: 'Gravel biking',
      es: 'Bicicleta de gravel',
      pt: 'Ciclismo gravel',
      pl: 'Gravel ',
      'pt-BR': 'Ciclismo gravel',
      sv: 'Gravelcykling',
      zh: '砾石自行车',
      ko: '그래블 바이크',
      ja: 'グラベルバイキング',
      ru: 'Гравийный велоспорт',
      th: 'Gravel biking',
      it: 'Ciclismo di gravel',
      nl: 'Fietsen op grind',
      tr: 'Çakıl bisikleti',
      id: 'Bersepeda kerikil'
    },
    permalinks: {
      en: 'gravel-biking',
      fr: 'gravel',
      de: 'gravel-biking',
      es: 'bicicleta-de-gravel',
      pt: 'ciclismo-gravel',
      pl: 'gravel',
      'pt-BR': 'ciclismo-gravel',
      sv: 'gravelcykling',
      ru: '',
      it: 'ciclismo-di-gravel',
      nl: 'fietsen-op-grind',
      tr: 'cakil-bisikleti'
    },
    order: 5,
    group: 'group_cycling',
    type: 'type_cycling',
    isFilter: true,
    titles: {
      en: 'Gravel biking races',
      fr: 'Courses de Gravel',
      de: 'Gravel Bike Rennen',
      es: 'Carreras de gravel',
      pt: 'Corridas de bicicleta em gravilha',
      pl: 'Wyścigi gravel',
      'pt-BR': 'Corridas gravel bike',
      sv: 'Gravel Cykellopp',
      zh: '砾石自行车赛',
      ko: '그래블 바이크 레이스',
      ja: 'グラベルバイキングレース',
      ru: 'Гравийные гонки на велосипедах ',
      th: 'Gravel biking races',
      it: 'Gare di ciclismo gravel',
      nl: 'Gravel motorraces',
      tr: 'Çakıl bisiklet yarışları',
      id: 'Perlombaan bersepeda kerikil'
    }
  },
  '32': {
    id: 32,
    code: 'paddle_triathlon',
    display: 'multi_activity',
    names: {
      en: 'Paddle triathlon',
      fr: 'Triathlon à rame',
      de: 'Paddel-triathlon',
      es: 'Triatlón padel',
      pt: 'Paddle triatlo',
      pl: 'Paddle triathlon',
      'pt-BR': 'Paddle triatlo',
      sv: 'Paddle tri',
      zh: '划桨三项赛',
      ko: '패들 트라이애슬론',
      ja: 'パドルトライアスロン',
      ru: 'Гребной триатлон',
      th: 'Paddle triathlon',
      it: 'Triathlon a pagaia',
      nl: 'Triatlon peddelen',
      tr: 'Kürek triatlonu',
      id: 'Paddle triathlon'
    },
    permalinks: {
      en: 'paddle-triathlon',
      fr: 'triathlon-a-rame',
      de: 'paddel-triathlon',
      es: 'triatlon-padel',
      pt: 'paddle-triatlo',
      pl: 'paddle-triathlon',
      'pt-BR': 'paddle-triatlo',
      sv: 'paddle-tri',
      ru: '',
      it: 'triathlon-a-pagaia',
      nl: 'triatlon-peddelen',
      tr: 'kurek-triatlonu'
    },
    order: 28,
    group: 'group_triathlon',
    type: 'type_multi_sport',
    isFilter: true,
    titles: {
      en: 'Paddle Triathlon',
      fr: 'Triathlon Paddle',
      de: 'Paddle Triathlon',
      es: 'Triatlón con paddle',
      pt: 'Triatlo a remo',
      pl: 'Paddle triathlon',
      'pt-BR': 'Triatlo com remo',
      sv: 'Paddel Triathlon',
      zh: '浆板三项赛',
      ko: '패들 트라이애슬론',
      ja: 'パドルトライアスロン',
      ru: 'Триатлон с греблей',
      th: 'Paddle Triathlon',
      it: 'Triathlon a pagaia',
      nl: 'Triatlon peddelen',
      tr: 'Kürek Triatlonu',
      id: 'Dayung Triathlon'
    }
  }
};
export default dict;
