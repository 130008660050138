import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import useSWR from 'swr';

import tailwindConfig from '../../../../tailwind.config';
import { fetcherPOST } from '../../../clients/axios/client';
import clsxm from '../../../lib/clsxm';
import {
  DEFAULT_EDITION_ID_OPTIONS,
  DEFAULT_RACE_ID_OPTIONS,
  REVIEW_MOBILE_BREAKPOINT
} from '../../../lib/reviews/constants';
import { ListData } from '../../../types';
import { Side, Size } from '../../../types/enums';
import { EventPageData } from '../../../types/eventPageTypes';
import { hasOneOrMore, isEmpty } from '../../../utils/arrays';
import useMediaQuery from '../../../utils/useMediaQuery';
import AhotwoProperty from '../../atoms/AhotwoProperty';
import { AhotuForm } from '../../atoms/form';
import AhotuButton from '../../atoms/form/AhotuButton';
import { Heading } from '../../atoms/Heading';
import RatingStarSelect from '../../atoms/RatingStarSelect';
import { AhotwoArrowRightIcon } from '../../icons/arrow';
import { OneDieIcon } from '../../icons/icons';
import UserDisplay from '../../molecules/UserDisplay';

interface WriteReviewFormProps {
  event: EventPageData;
  isSaving: boolean;
}

const WriteReviewForm = ({ event, isSaving = false }: WriteReviewFormProps) => {
  const { data: session } = useSession();
  const { t } = useTranslation();
  const router = useRouter();
  const isMobile = useMediaQuery(REVIEW_MOBILE_BREAKPOINT);

  const [racesOptions, setRacesOptions] = useState([]);
  const {
    formState: { errors },
    setValue,
    register
  } = useFormContext();

  const { data, isLoading } = useSWR(
    { url: '/api/reviews/writeReviewInputs', data: { permalink: event.permalink, locale: router.locale } },
    fetcherPOST
  );
  const edition = useWatch({ name: `edition`, defaultValue: DEFAULT_EDITION_ID_OPTIONS });
  const race = useWatch({ name: `race`, defaultValue: DEFAULT_RACE_ID_OPTIONS });
  const rating = useWatch({ name: `rating` });

  useEffect(() => {
    //Reset raceId to default when changing edition
    setValue('race', DEFAULT_RACE_ID_OPTIONS);
  }, [edition]);

  useEffect(() => {
    const lastEdition = data?.options?.[0];
    setValue('edition', lastEdition);
    setValue('eventId', data?.eventId);
    setRacesOptions(data?.options?.find(ed => ed.value === lastEdition.value)?.races);
  }, [data]);

  const setRating = rating => {
    setValue('rating', rating);
  };

  useEffect(() => {
    if (errors.rating) {
      document.getElementById('writeReviewSection').scrollIntoView();
    }
  }, [errors]);

  const onReviewInput = () => {
    const textArea = document.getElementById('text');
    textArea.style.height = 'inherit';
    textArea.style.height = `${textArea.scrollHeight}px`;
    textArea.scrollIntoView();
  };

  return (
    <div className={clsxm(' pb-14', isMobile ? '' : 'sm:mx-[44px] md:mx-[88px]')} id="writeReviewSection">
      <div className="flex flex-col space-y-6">
        <UserDisplay name={session?.firstName || ''} countryCode={session?.country?.iso2} avatarUrl={session?.avatar} />
        <Heading headingLevel="h2" size="2xl">
          {event?.name}
        </Heading>
        <div className="flex flex-col space-y-3">
          <AhotuForm.Group className={clsxm('w-full', isMobile && 'flex items-center')}>
            <RatingStarSelect rating={rating} starClassName="w-10 h-10" onClick={setRating} />
            {errors.rating && <AhotuForm.Feedback type="invalid">{errors.rating?.message}</AhotuForm.Feedback>}
          </AhotuForm.Group>

          {(isLoading || !isEmpty(data.options)) && (
            <>
              <AhotuForm.Group className="w-full">
                <AhotuForm.Label htmlFor="edition">{t('event-page:write-review-edition-label')}</AhotuForm.Label>
                <AhotuForm.MultiSelect
                  selectedOptions={edition}
                  multiple={false}
                  options={data?.options}
                  onChange={(x: ListData) => {
                    setValue('edition', x);
                    setRacesOptions(data?.options?.find(ed => ed.value === x.value)?.races);
                  }}
                />

                {errors.edition && (
                  <AhotuForm.Feedback type="invalid">{errors.edition?.['value']?.['message']}</AhotuForm.Feedback>
                )}
              </AhotuForm.Group>

              {(isLoading || hasOneOrMore(racesOptions)) && (
                <AhotuForm.Group className="w-full">
                  <AhotuForm.Label htmlFor="race">{t('event-page:write-review-race-label')}</AhotuForm.Label>
                  <AhotuForm.MultiSelect
                    selectedOptions={race}
                    className="!h-[76px]"
                    OptionComponent={RaceOption}
                    multiple={false}
                    options={racesOptions}
                    onChange={(x: ListData) => {
                      setValue('race', x);
                    }}
                  />
                  {errors.raceId && (
                    <AhotuForm.Feedback type="invalid">{errors.raceId?.['value']?.['message']}</AhotuForm.Feedback>
                  )}
                </AhotuForm.Group>
              )}
            </>
          )}
          <AhotuForm.Group className="w-full">
            <AhotuForm.Label htmlFor="text">{t('event-page:write-review-text-label')}</AhotuForm.Label>
            <AhotuForm.TextArea
              id="text"
              onInput={onReviewInput}
              {...register('text')}
              placeholder={t('event-page:write-review-text-placeholder')}
            />

            {errors.text && <AhotuForm.Feedback type="invalid">{errors.text?.message}</AhotuForm.Feedback>}
          </AhotuForm.Group>

          <AhotuForm.Group className="w-full">
            <AhotuForm.Check
              label={t('event-page:write-review-checkbox-label')}
              id="isAuthentic"
              {...register('isAuthentic')}
            />
            {errors.isAuthentic && (
              <AhotuForm.Feedback type="invalid">{errors.isAuthentic?.message}</AhotuForm.Feedback>
            )}
          </AhotuForm.Group>
        </div>
      </div>
      <AhotuButton variant="stroke" type="submit" className="mt-10 w-full" showSpinner={isSaving}>
        {t('event-page:submit-review')}
      </AhotuButton>
    </div>
  );
};

export default WriteReviewForm;

const RaceOption = ({ option, showOverflow = false }): JSX.Element => (
  <div>
    <span>{option?.text}</span>
    <div className={clsxm('flex', showOverflow ? 'flex-wrap' : ' overflow-hidden')}>
      <span className="text-sm text-gray-70 ">{option?.sport}</span>
      {hasOneOrMore(option?.distances) && (
        <span className="block">
          <OneDieIcon className="h-4 w-4" fill={tailwindConfig.theme.colors['gray']['70']} />
        </span>
      )}
      {option?.distances?.map((distance, idx) => (
        <AhotwoProperty
          className="text-gray-70 text-sm w-fit items-center flex-shrink-0 space-x-1 "
          key={idx}
          iconPosition={Side.right}
          icon={idx != option?.distances.length - 1 && AhotwoArrowRightIcon}
          fill={tailwindConfig.theme.colors['gray']['70']}
          size={Size.sm}>
          {distance}
        </AhotwoProperty>
      ))}
    </div>
  </div>
);
