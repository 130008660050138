import { DropdownLink } from '~/src/types/eventPageTypes';

import { TopNavigationMenu } from '../../types/homePageTypes';
import useMediaQuery from '../../utils/useMediaQuery';
import DesktopNavigation from '../molecules/navigation/DesktopNavigation';
import MobileNavigation from '../molecules/navigation/MobileNavigation';
interface TopNavigationProps {
  isAnimated: boolean;
  menu: TopNavigationMenu;
  otherLanguagesUrls?: DropdownLink[];
}

const TopNavigation = ({ isAnimated, menu, otherLanguagesUrls }: TopNavigationProps) => {
  const isMobile = useMediaQuery('lg');

  return (
    <>
      {(isMobile === undefined || isMobile) && <MobileNavigation menu={menu} otherLanguagesUrls={otherLanguagesUrls} />}

      {!isMobile && <DesktopNavigation menu={menu} isAnimated={isAnimated} />}
    </>
  );
};

export default TopNavigation;
