import { AnimatePresence } from 'framer-motion';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { DropdownLink } from '~/src/types/eventPageTypes';

import clsxm from '../../../lib/clsxm';
import { MenuLevel } from '../../../lib/globale/constantes';
import type { TopNavigationMenu } from '../../../types/homePageTypes';
import { SportType } from '../../../types/referenceTypes';
import { AhotuLogo } from '../../atoms/AhotuLogo';
import PanelSliderMobile from '../../atoms/PanelSliderMobile';
import { AhotwoHamburgerIcon, GlobeIcon, SearchIcon, XIcon } from '../../icons/icons';
import TopNavigationSearchMobile from '../TopNavigationSearchMobile';
import FirstLevelMobileNav from './FirstLevelMobileNav';
import SecondLevelMobileNav from './SecondLevelMobileNav';

interface MobileNavigationProps {
  menu: TopNavigationMenu;
  otherLanguagesUrls?: DropdownLink[];
}
const MobileNavigation = ({ menu, otherLanguagesUrls }: MobileNavigationProps) => {
  const [mobileMenu, setMobileMenu] = useState(false);
  const [level, setLevel] = useState(MenuLevel.DEFAULT);
  const [sportType, setSportType] = useState<SportType | ''>('');
  const toggleMenu = () => {
    setLevel(MenuLevel.DEFAULT);
    setMobileMenu(!mobileMenu);
  };

  const router = useRouter();
  const path = router.asPath;

  useEffect(() => {
    setMobileMenu(false);
  }, [path]);

  const { t } = useTranslation();

  const [searchIsExpanded, setSearchIsExpanded] = useState(false);

  return (
    <>
      <div className="flex flex-row justify-between items-center bg-white min-w-[100%] px-4 h-[73px] lg:hidden">
        <div onClick={toggleMenu}>
          <AhotwoHamburgerIcon className="h-7 w-7" />
        </div>
        <div className="hover:cursor-pointer">
          <AhotuLogo />
        </div>
        <div id="topNavigationSearchIcon" onClick={() => setSearchIsExpanded(true)}>
          <SearchIcon fill="black" className="h-6 w-6" />
        </div>
      </div>
      <AnimatePresence>
        {mobileMenu && (
          <PanelSliderMobile side="left">
            <div className={`top-0 left-0 min-w-[100%] bg-white z-20 p-6`}>
              <div className="flex flex-col w-[100%]">
                <div className="flex flex-row justify-end" onClick={toggleMenu}>
                  <XIcon className="h-4 w-4" />
                </div>
                <div className={clsxm('mt-4 flex flex-col')}>
                  {level === MenuLevel.DEFAULT && (
                    <FirstLevelMobileNav
                      menu={menu}
                      setMobileMenu={setMobileMenu}
                      setLevel={setLevel}
                      setSportType={setSportType}
                      otherLanguagesUrls={otherLanguagesUrls}
                    />
                  )}
                  {level === MenuLevel.SPORTS && (
                    <SecondLevelMobileNav
                      setMobileMenu={setMobileMenu}
                      setLevel={setLevel}
                      level={level}
                      sportType={sportType}
                      menu={menu}
                      title={menu[sportType].name}
                    />
                  )}
                  {level === MenuLevel.LOCALES && (
                    <SecondLevelMobileNav
                      setMobileMenu={setMobileMenu}
                      setLevel={setLevel}
                      level={level}
                      title={t('common:language')}
                      titleIcon={GlobeIcon}
                      otherLanguagesUrls={otherLanguagesUrls}
                    />
                  )}
                  {level === MenuLevel.CURRENCIES && (
                    <SecondLevelMobileNav
                      setMobileMenu={setMobileMenu}
                      setLevel={setLevel}
                      level={level}
                      title={`$ ${t('footer:currency')}`}
                    />
                  )}
                </div>
              </div>
            </div>
          </PanelSliderMobile>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {searchIsExpanded && (
          <TopNavigationSearchMobile setSearchIsExpanded={setSearchIsExpanded} searchIsExpanded={searchIsExpanded} />
        )}
      </AnimatePresence>
    </>
  );
};

export default MobileNavigation;
