import Image from 'next/image';

import tailwindConfig from '../../../tailwind.config';
import Flag from '../atoms/Flag';
import { AhotuPersonFilled } from '../icons/icons';
interface UserDisplayProps {
  name: string;
  countryCode: string;
  avatarUrl: string;
}
const NO_AVATAR = '/no-avatar.png';
const UserDisplay = ({ name, countryCode, avatarUrl }: UserDisplayProps) => {
  return (
    <div className="flex items-center">
      <div className="bg-gray-20 w-10 h-10 rounded-full p-1 flex-shrink-0 relative">
        {avatarUrl === NO_AVATAR || !avatarUrl ? (
          <AhotuPersonFilled className="text-white" fill={tailwindConfig.theme.colors['white']} />
        ) : (
          <Image src={avatarUrl} fill alt="user avatar" className="rounded-full object-cover" />
        )}
      </div>
      <span className="inline-block ml-2 font-bold line-clamp-1">{name}</span>
      {countryCode && <Flag code={countryCode} className="ml-2 h-7 w-7  border" />}
    </div>
  );
};

export default UserDisplay;
