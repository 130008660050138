import Image from 'next/image';
import Link from 'next/link';
import React from 'react';

import config from '../../../lib/config';

type Props = {
  imagesAndLinks: {
    imageId: string;
    alt: string;
    link: string;
  }[];
};

const LinkGrid = ({ imagesAndLinks }: Props) => {
  return (
    <div className="grid grid-cols-2 gap-4 px-4 lg:gap-10 lg:px-10">
      {imagesAndLinks.map(({ imageId, alt, link }, index) => {
        return (
          <Link href={link} key={index} target="_blank">
            <div className={`relative w-full pt-[100%] hover:brightness-75 transition-all cursor-pointer`}>
              <Image src={`${config.CMS_ASSETS_URL}${imageId}`} fill alt={alt} className="object-cover" sizes="50vw" />
            </div>
          </Link>
        );
      })}
    </div>
  );
};

export default LinkGrid;
